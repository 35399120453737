@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

* {
  transition: all .4s;
  border-radius: 0 !important;
  font-family: 'Roboto', sans-serif !important;
}

h2[class*="MuiDialogTitle-root"] {
  background-color: #364354;
  color: #f3ca12
}

.dots::after {
  width: 10px;
  content: "";
  animation: dot 3s linear infinite alternate
}

@keyframes dot {
  0% {
    content: "\00a0\00a0\00a0"
  }
  33% {
    content: ".\00a0\00a0";
  }
  66% {
    content: "..\00a0";
  }
  100% {
    content: "...";
  }
}

::-moz-selection { background: #f3ca12; color: #fff }
::selection { background: #f3ca12; color: #fff }